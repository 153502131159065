import React from "react";
import "/src/assets/scss/components/heros.scss";
import videoBoat from "/src/assets/videos/video-boat.mp4";

function HeroHomepage() {
  return (
    <div className="hero hero-homepage">
      <video
        autoPlay="autoplay"
        loop="loop"
        muted
        className="hero-homepage-video"
      >
        <source src={videoBoat} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default HeroHomepage;
