import React from "react";
import { Link } from "gatsby";
import "/src/assets/scss/components/cards/card.scss";

function Card(props) {
  const { title, imageSrc, imageAlt } = props;
  return (
    <>
      <img src={imageSrc} alt={imageAlt} className="card-image" />
      <h4 className="card-title">{title}</h4>
      <Link to="/products" className="btn">
        SEE MORE
      </Link>
    </>
  );
}

export default Card;
