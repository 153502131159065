import React from "react";
import "/src/assets/scss/components/cards/card-list.scss";
import Card from "./Card";

function CardList(props) {
  const { sectionTitle, cards } = props;
  const listItems = cards.map(({ cardTitle, imageSrc, imageAlt }, index) => (
    <li key={index} className="card-col">
      <Card title={cardTitle} imageSrc={imageSrc} imageAlt={imageAlt} />
    </li>
  ));
  return (
    <section className="card-list-section">
      <div className="container">
        <h3 className="title-2">{sectionTitle}</h3>
        <ul className="card-list-row">
          {listItems}
        </ul>
      </div>
    </section>
  );
}

export default CardList;
