import React from "react";
import { Link } from "gatsby";
import "/src/assets/scss/components/barker.scss";

function Barker(props) {
  const { title, text } = props;
  return (
    <section className="barker">
      <div className="container">
        <div className="barker-row">
          <h2 className="title-2">{title}</h2>
          <p className="barker-text">
            {text}
          </p>
          <Link
            to="/about"
            className="btn btn-wire"
          >LEARN MORE</Link>
        </div>
      </div>
    </section>
  );
}

export default Barker;
