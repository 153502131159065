import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/structure/Layout";
import HeroHomepage from "../components/heros/HeroHomepage";
import Barker from "../components/Barker";
import CardList from "../components/cards/CardList";
import imgMahiMahi from "../static/images/products/img-mahi-mahi-500x325.png";
import imgSwordFish from "../static/images/products/img-swordfish-500x325.png";
import imgTunaFish from "../static/images/products/img-tuna-500x325.png";
import ContactForm from "../components/forms/ContactForm";

function Index() {
  const barkerTitle = "FANTASTIC FISH COMPANY";
  const barkerText =
    "Fantastic Fish - It is not just our name, it’s our philosophy. Quality, integrity, and customer service are what you get, when you choose us. Fantastic Fish is the first choice for fresh fish intended for wholesalers, distributors, and supermarkets. Drop us a line and see how we can help you today!";
  const cardListSectionTitle = "OUR PRODUCTS";
  const cards = [
    {
      cardTitle: "Mahi-Mahi",
      imageSrc: imgMahiMahi,
      imageAlt: "Mahi-Mahi Fish",
    },
    { cardTitle: "Swordfish", imageSrc: imgSwordFish, imageAlt: "Swordfish" },
    { cardTitle: "Tuna", imageSrc: imgTunaFish, imageAlt: "Tuna Fish" },
  ];

  return (
    <div className="page-home">
      <Helmet>
        <html lang="en" />
        <title>Fantastic Fish Company</title>
        <meta
          name="description"
          content="Fantastic Fish - It is not just our name, it’s our philosophy.
          Quality, integrity, and customer service are what you get, when you
          choose us. Fantastic Fish is the first choice for fresh fish intended
          for wholesalers, distributors, and supermarkets."
        />
      </Helmet>
      <Layout>
        <HeroHomepage />
        <main id="main-content" tabIndex="-1">
          <Barker title={barkerTitle} text={barkerText} />
          <CardList sectionTitle={cardListSectionTitle} cards={cards} />
          <ContactForm />
        </main>
      </Layout>
    </div>
  );
}

export default Index;
