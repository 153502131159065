import React, { useState } from "react";
import "/src/assets/scss/components/forms/contact-form.scss";

function ContactForm() {
  const initialFormState = {
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState({ ...initialFormState });

  const handleChange = ({ target }) => {
    const value = target.value;
    setFormData({
      ...formData,
      [target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // const requestOptions = {
    //   method: "POST",
    //   body: formData,
    // };
    // fetch("/api/form-submit-url", requestOptions)
    //   .then((response) => response.json());
    // setFormData({ ...initialFormState });
  };

  return (
    <section className="contact-form-section">
      <div className="container">
        <div className="contact-form-section-header">
          <h4 className="title-2">CONTACT US</h4>
        </div>
        <form onSubmit={handleSubmit} className="contact-form">
          <label htmlFor="name" className="contact-form-label">
            <span className="contact-form-label-text">Name *</span>
            <input
              type="text"
              id="name"
              name="name"
              className="contact-form-input"
              onChange={handleChange}
              value={formData.name}
              required
            />
          </label>
          <label htmlFor="company" className="contact-form-label">
            <span className="contact-form-label-text">Company *</span>
            <input
              type="text"
              id="company"
              name="company"
              className="contact-form-input"
              onChange={handleChange}
              value={formData.company}
              required
            />
          </label>
          <label htmlFor="email" className="contact-form-label">
            <span className="contact-form-label-text">Email *</span>
            <input
              type="email"
              id="email"
              name="email"
              className="contact-form-input"
              onChange={handleChange}
              value={formData.email}
              required
            />
          </label>
          <label htmlFor="phone" className="contact-form-label">
            <span className="contact-form-label-text">Phone</span>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="contact-form-input"
              onChange={handleChange}
              value={formData.phone}
            />
          </label>
          <label htmlFor="message" className="contact-form-label">
            <span className="contact-form-label-text">Message *</span>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              className="contact-form-input"
              onChange={handleChange}
              value={formData.message}
              required
            />
          </label>
          <button type="submit" className="btn btn-wire contact-form-btn">
            SUBMIT
          </button>
        </form>
      </div>
    </section>
  );
}

export default ContactForm;
